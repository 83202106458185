import React from 'react';
import { Modal, Button } from 'react-bootstrap';

function ErrorModal({ show, onClose, missingFields }) {
    return (
        <Modal show={show} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Fehlende Felder</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Bitte füllen Sie die folgenden Felder aus:</p>
                <ul>
                    {missingFields.map((field, index) => (
                        <li key={index}>{field}</li>
                    ))}
                </ul>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ErrorModal;
