import React, { useEffect, useState } from 'react';
import api from '../api';
import '../styles/ManageUser.css';

function ManageUser() {
    const [users, setUsers] = useState([]);
    const [currentUser, setCurrentUser] = useState(null);
    const [showUserModal, setShowUserModal] = useState(false);
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [newRole, setNewRole] = useState('');
    const [newPassword, setNewPassword] = useState('');

    useEffect(() => {
        loadUsers();
    }, []);

    const loadUsers = async () => {
        try {
            const response = await api.get('/api/user-list');
            const usersData = Array.isArray(response.data) ? response.data : [];
            setUsers(usersData);
        } catch (error) {
            console.error('Error loading users:', error);
            setUsers([]);
        }
    };

    const loadCurrentUser = async (id) => {
        try {
            const response = await api.get(`/api/user/${id}`);
            setCurrentUser(response.data);
        } catch (error) {
            console.error('Error loading current user:', error);
        }
    };

    const handleUserEdit = (user) => {
        setCurrentUser(user);
        setShowUserModal(true);
    };

    const handleUserSave = async () => {
        const method = currentUser.id ? 'put' : 'post';
        const url = currentUser.id ? `/api/user/${currentUser.id}` : '/api/users';
        try {
            await api[method](url, currentUser);
            setShowUserModal(false);
            setCurrentUser(null);
            loadUsers();
        } catch (error) {
            console.error('Error saving user:', error);
        }
    };

    const handleUserChange = (e) => {
        setCurrentUser({
            ...currentUser,
            [e.target.name]: e.target.value,
        });
    };

    const openPasswordModal = (user) => {
        setCurrentUser(user);
        setNewPassword('');
        setShowPasswordModal(true);
    };

    const handlePasswordChange = async () => {
        try {
            console.log(currentUser.username + " 2");
            console.log(newPassword);
            const response = await api.post('/auth/admin/changePassword', {
                username: currentUser.username,
                newPassword: newPassword,
            });

            if (response.status === 200) {
                alert("Passwort wurde erfolgreich geändert.");
            } else {
                alert("Fehler beim Ändern des Passworts.");
            }
            setShowPasswordModal(false);
        } catch (error) {
            console.error("Fehler beim Ändern des Passworts:", error);
            alert("Ein Fehler ist aufgetreten. Bitte versuche es später erneut.");
        }
    };

    const lockUser = async (id) => {
        try {
            await api.put(`/api/user/${id}/lock`);
            loadUsers();
        } catch (error) {
            console.error('Error locking user:', error);
        }
    };

    const unlockUser = async (id) => {
        try {
            await api.put(`/api/user/${id}/unlock`);
            loadUsers();
        } catch (error) {
            console.error('Error unlocking user:', error);
        }
    };

    const addRole = async () => {
        if (newRole) {
            try {
                await api.put(`/api/user/${currentUser.id}/addRole/${newRole}`);
                setNewRole('');
                await loadUsers();
                await loadCurrentUser(currentUser.id);
            } catch (error) {
                console.error('Error adding role:', error);
            }
        }
    };

    const removeRole = async (role) => {
        try {
            await api.put(`/api/user/${currentUser.id}/removeRole/${role}`);
            await loadUsers();
            await loadCurrentUser(currentUser.id);
        } catch (error) {
            console.error('Error removing role:', error);
        }
    };

    return (
        <div className="manage-user-container">
            <h4>Benutzerliste</h4>
            <ul className="manage-user-list">
                {users.map((user) => (
                    <li className="manage-user-list-item" key={user.id}>
                        <strong>{user.username}</strong>
                        <p>Rollen: {user.roles.map(role => role).join(', ')}</p>
                        <p>Status: {user.isLocked ? 'Gesperrt' : 'Aktiv'}</p>
                        <div className="manage-user-actions">
                            <button className="manage-user-edit-button" onClick={() => handleUserEdit(user)}>Bearbeiten</button>
                            <button className="manage-user-password-button" onClick={() => openPasswordModal(user)}>Passwort ändern</button>
                            {user.isLocked ? (
                                <button className="manage-user-unlock-button" onClick={() => unlockUser(user.id)}>Entsperren</button>
                            ) : (
                                <button className="manage-user-lock-button" onClick={() => lockUser(user.id)}>Sperren</button>
                            )}
                        </div>
                    </li>
                ))}
            </ul>

            {showUserModal && (
                <div className="manage-user-modal">
                    <div className="manage-user-modal-content">
                        <h5>Benutzer bearbeiten</h5>
                        <input
                            type="text"
                            name="username"
                            value={currentUser?.username || ''}
                            onChange={handleUserChange}
                            placeholder="Benutzername"
                        />

                        <h6>Rollen</h6>
                        <ul>
                            {currentUser?.roles?.map((role) => (
                                <li key={role}>
                                    {role}
                                    <button onClick={() => removeRole(role)}>Rolle entfernen</button>
                                </li>
                            ))}
                        </ul>
                        <input
                            type="text"
                            value={newRole}
                            onChange={(e) => setNewRole(e.target.value)}
                            placeholder="Neue Rolle hinzufügen"
                        />
                        <button onClick={addRole}>Rolle hinzufügen</button>

                        <button onClick={handleUserSave}>Speichern</button>
                        <button onClick={() => setShowUserModal(false)}>Abbrechen</button>
                    </div>
                </div>
            )}

            {showPasswordModal && (
                <div className="manage-user-modal">
                    <div className="manage-user-modal-content">
                        <h5>Passwort für {currentUser?.username} ändern</h5>
                        <input
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            placeholder="Neues Passwort"
                        />
                        <button onClick={handlePasswordChange}>Passwort speichern</button>
                        <button onClick={() => setShowPasswordModal(false)}>Abbrechen</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ManageUser;
