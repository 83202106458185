import React, { useState, useEffect } from 'react';
import '../styles/Navbar.css';

const Navbar = ({ onLogout }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isSr, setIsSr] = useState(false);
    const [isJr, setIsJr] = useState(false);


    useEffect(() => {
        // Rollen aus localStorage laden und prüfen, ob ADMIN vorhanden ist
        const roles = JSON.parse(localStorage.getItem('userRoles')) || [];
        setIsAdmin(roles.includes('ROLE_ADMIN'));
        setIsSr(roles.includes('ROLE_SR'));
        setIsJr(roles.includes('ROLE_JR'));
    }, []);

    const toggleNavbar = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className={`navbar ${isExpanded ? 'expanded' : ''}`}>
            <div className="hamburger" onClick={toggleNavbar}>
                &#9776;
            </div>
            <div className="nav-left">
                <a href="home" onClick={() => setIsExpanded(false)}>Startseite</a>
                {(isSr || isAdmin) && <a href="register" onClick={() => setIsExpanded(false)}>Registrieren</a>}
                {(isSr || isAdmin) && <a href="board" onClick={() => setIsExpanded(false)}>Zuordnung</a>}
                {isJr && <a href="manageFirmlinge" onClick={() => setIsExpanded(false)}>Firmlinge</a>}
                {(isSr || isAdmin) && <a href="managea" onClick={() => setIsExpanded(false)}>Aktivitäten</a>}
                {/* Zeige den Link nur an, wenn der Benutzer die Rolle ADMIN hat */}
                {isAdmin && <a href="manageu" onClick={() => setIsExpanded(false)}>User</a>}
            </div>
            <div className="nav-right">
                <button onClick={onLogout} className="logout-button">
                    Abmelden
                </button>
            </div>
        </div>
    );
};

export default Navbar;
