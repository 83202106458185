import React from 'react';
import { Modal, Button } from 'react-bootstrap';

function ConfirmationModal({ show, onClose, formData }) {
    return (
        <Modal show={show} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Zusammenfassung</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ul>
                    <li><strong>Vorname:</strong> {formData.firstName}</li>
                    <li><strong>Nachname:</strong> {formData.lastName}</li>
                    {/* Weitere Felder */}
                </ul>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>Abbrechen</Button>
                <Button variant="primary">Bestätigen</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ConfirmationModal;