import React, {useEffect, useState} from 'react';
import api from '../api';
import '../styles/ManageActivity.css';

const FirmDetailsModal = ({firmlingId, onClose}) => {
    const [firmDetails, setFirmDetails] = useState({
        firmsonntage: [],
        firmstunden: [],
        sonstigeAktionen: [
            {id: 'ausflug', name: 'Ausflug', completed: false},
            {id: 'sozialeAktion', name: 'Soziale Aktion', completed: false}
        ]
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const loadFirmDetails = async () => {
            try {
                const firmsonntageResponse = await api.get(`/api/firmsonntag/list/${firmlingId}`);
                const firmstundenResponse = await api.get(`/api/firmstunde/list/${firmlingId}`);
                const ausflugResponse = await api.get(`/api/firmlinge/ausflug/${firmlingId}`);
                const sozialeAktionResponse = await api.get(`/api/firmlinge/aktion/${firmlingId}`);

                setFirmDetails({
                    firmsonntage: firmsonntageResponse.data,
                    firmstunden: firmstundenResponse.data,
                    sonstigeAktionen: [
                        {id: 'ausflug', name: 'Ausflug', completed: ausflugResponse.data},
                        {id: 'sozialeAktion', name: 'Soziale Aktion', completed: sozialeAktionResponse.data}
                    ]
                });
            } catch (error) {
                console.error('Error loading Firmdetails:', error);
            } finally {
                setLoading(false);
            }
        };

        loadFirmDetails();
    }, [firmlingId]);

    const toggleCompletion = async (id, completed, type) => {
        try {
            let endpoint = '';

            switch (type) {
                case 'firmsonntag':
                    endpoint = completed ? `/api/firmsonntag/complete/${firmlingId}/${id}` : `/api/firmsonntag/uncomplete/${firmlingId}/${id}`;
                    break;
                case 'firmstunde':
                    endpoint = completed ? `/api/firmstunde/complete/${firmlingId}/${id}` : `/api/firmstunde/uncomplete/${firmlingId}/${id}`;
                    break;
                case 'sonstigeAktionen':
                    if (id === 'ausflug') {
                        endpoint = completed ? `/api/firmlinge/checkAusflug/${firmlingId}` : `/api/firmlinge/uncheckAusflug/${firmlingId}`;
                    } else if (id === 'sozialeAktion') {
                        endpoint = completed ? `/api/firmlinge/checkSozialeAktion/${firmlingId}` : `/api/firmlinge/uncheckSozialeAktion/${firmlingId}`;
                    }
                    break;
                default:
                    return;
            }

            await api.put(endpoint);

            setFirmDetails((prevDetails) => {
                let updatedDetails = {...prevDetails};
                if (type === 'firmsonntag') {
                    updatedDetails.firmsonntage = prevDetails.firmsonntage.map((item) =>
                        item.firmsonntagId === id ? {...item, completed} : item
                    );
                } else if (type === 'firmstunde') {
                    updatedDetails.firmstunden = prevDetails.firmstunden.map((item) =>
                        item.firmstundeId === id ? {...item, completed} : item
                    );
                } else if (type === 'sonstigeAktionen') {
                    updatedDetails.sonstigeAktionen = prevDetails.sonstigeAktionen.map((item) =>
                        item.id === id ? {...item, completed} : item
                    );
                }
                return updatedDetails;
            });
        } catch (error) {
            console.error('Error updating completion status:', error);
        }
    };

    return (
        <div className="manage-activity-modal">
            <div className="manage-activity-modal-content">
                {loading ? (
                    <div className="loading-animation">Laden...</div>
                ) : (
                    <>
                        <h2 className="manage-activity-header">Firmsonntage und Firmstunden Details</h2>

                        <h4>Firmsonntage</h4>
                        <ul className="manage-activity-list">
                            {firmDetails.firmsonntage.map(firmsonntag => (
                                <li
                                    key={firmsonntag.firmsonntagId}
                                    className={`manage-activity-list-item ${firmsonntag.completed ? 'completed' : ''}`}
                                    onClick={() => toggleCompletion(firmsonntag.firmsonntagId, !firmsonntag.completed, 'firmsonntag')}
                                >
                                    <strong>{firmsonntag.firmsonntagName}</strong>
                                </li>
                            ))}
                        </ul>

                        <h4>Firmstunden</h4>
                        <ul className="manage-activity-list">
                            {firmDetails.firmstunden.map(firmstunde => (
                                <li
                                    key={firmstunde.firmstundeId}
                                    className={`manage-activity-list-item ${firmstunde.completed ? 'completed' : ''}`}
                                    onClick={() => toggleCompletion(firmstunde.firmstundeId, !firmstunde.completed, 'firmstunde')}
                                >
                                    <strong>{firmstunde.firmstundeName}</strong>
                                </li>
                            ))}
                        </ul>

                        <h4>Sonstige Aktionen</h4>
                        <ul className="manage-activity-list">
                            {firmDetails.sonstigeAktionen.map(action => (
                                <li
                                    key={action.id}
                                    className={`manage-activity-list-item ${action.completed ? 'completed' : ''}`}
                                    onClick={() => toggleCompletion(action.id, !action.completed, 'sonstigeAktionen')}
                                >
                                    <strong>{action.name}</strong>
                                </li>
                            ))}
                        </ul>

                        <button onClick={onClose} className="manage-activity-cancel-button">Schließen</button>
                    </>
                )}
            </div>
        </div>
    );
};

export default FirmDetailsModal;
