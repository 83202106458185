import { BrowserRouter as Router, Route, Navigate, Routes } from 'react-router-dom';
import Navbar from "./components/Navbar";
import LoginPage from "./pages/LoginPage";
import Board from "./pages/Board";
import { useEffect, useState } from "react";
import RegistrationForm from "./pages/RegistrationForm";
import  './App.css'
import FirmlingeList from "./pages/FirmlingeListe";
import ManageActivity from "./pages/ManageActivity";
import ManageUser from "./pages/ManageUser";
import HomePage from "./pages/HomePage";
// Funktion zum Überprüfen des Authentifizierungsstatus
const checkAuthStatus = () => {
    return !!(localStorage.getItem('jwtTokenFIRM') || sessionStorage.getItem('jwtToken'));
};

// PrivateRoute-Komponente für geschützte Routen
const PrivateRoute = ({ isLoggedIn, children }) => {
    return isLoggedIn ? children : <Navigate to="/login" replace />;
};

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(checkAuthStatus());

    useEffect(() => {
        setIsLoggedIn(checkAuthStatus());
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('jwtToken');
        sessionStorage.removeItem('jwtToken');
        setIsLoggedIn(false);
    };

    return (
        <Router>
            {isLoggedIn && <Navbar onLogout={handleLogout} />}
            <Routes>
                <Route
                    path="/"
                    element={<Navigate to={isLoggedIn ? "/home" : "/login"} replace />}
                />
                <Route
                    path="/login"
                    element={
                        isLoggedIn ? <Navigate to="/home" replace /> : <LoginPage onLogin={() => setIsLoggedIn(true)} />
                    }
                />
                <Route
                    path="/home"
                    element={
                        <PrivateRoute isLoggedIn={isLoggedIn}>
                            <HomePage />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/board"
                    element={
                        <PrivateRoute isLoggedIn={isLoggedIn}>
                            <Board />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/register"
                    element={
                        <PrivateRoute isLoggedIn={isLoggedIn}>
                            <RegistrationForm />
                        </PrivateRoute>
                    }
                />
                <Route
                path="/manageFirmlinge"
                element={
                    <PrivateRoute isLoggedIn={isLoggedIn}>
                        <FirmlingeList />
                    </PrivateRoute>
                }
            />
                <Route
                path="/managea"
                element={
                    <PrivateRoute isLoggedIn={isLoggedIn}>
                        <ManageActivity />
                    </PrivateRoute>
                }
            />
                <Route
                    path="/manageu"
                    element={
                        <PrivateRoute isLoggedIn={isLoggedIn}>
                            <ManageUser />
                        </PrivateRoute>
                    }
                />
                {/* Weitere Routen hier hinzufügen */}
            </Routes>
        </Router>
    );
}

export default App;
