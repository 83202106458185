import React, { useState } from 'react';
import '../styles/LoginPage.css';
import api from '../api';

function setToken(token) {
    localStorage.setItem('jwtTokenFIRM', token);
}

function setRoles(roles) {
    localStorage.setItem('userRoles', JSON.stringify(roles));
}

function setLocalUserName(localUserName) {
    localStorage.setItem("localUserName", localUserName);
}

const LoginPage = ({ onLogin }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleLogin = async () => {
        setLoading(true);
        let isMounted = true;
        try {
            const response = await api.post('/auth/login', { username, password });
            console.log(response.data);

            // Speichern des Tokens und der Rollen in localStorage
            setToken(response.data.token);
            setRoles(response.data.roles);
            setLocalUserName(username);

            onLogin?.();
        } catch (error) {
            if (isMounted) {
                setErrorMessage(error.response?.data || error.message || 'Fehler beim Login');
            }
        } finally {
            if (isMounted) {
                setLoading(false);
            }
        }
        return () => { isMounted = false };
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            if (password.trim() === '') {
                document.getElementById('password-input').focus();
            } else {
                handleLogin();
            }
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(prevState => !prevState);
    };

    return (
        <div className="login-wrapper">
            <div className="login-container">
                <h2>Firmung Control Panel</h2>
                <div className="form-group">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Anmelden mit Benutzername"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        onKeyDown={handleKeyDown}
                        required
                    />
                </div>
                <div className="form-group position-relative">
                    <input
                        id="password-input"
                        type={showPassword ? 'text' : 'password'}
                        className="form-control"
                        placeholder="Passwort"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyDown={handleKeyDown}
                        required
                    />
                    <button
                        type="button"
                        onClick={togglePasswordVisibility}
                        className="toggle-password"
                        title={showPassword ? "Passwort verbergen" : "Passwort anzeigen"}
                    >
                        <i className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}/>
                    </button>
                </div>
                <button
                    type="button"
                    className="btn btn-primary btn-block"
                    onClick={handleLogin}
                >
                    {loading ? 'Laden...' : '➜ Anmelden'}
                </button>
                {errorMessage && <div className="error-message">{errorMessage}</div>}
            </div>
        </div>
    );
};

export default LoginPage;
