import React from 'react';

function TimeSlot({ selectedSlots, onSelect }) {
    const days = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag'];
    const times = ['16:00', '16:30', '17:00', '17:30', '18:00', '18:30'];

    const toggleSlot = (day, time) => {
        const slot = `${day}-${time}`;
        const newSlots = selectedSlots.includes(slot) ? selectedSlots.filter(s => s !== slot) : [...selectedSlots, slot];
        onSelect(newSlots);
    };

    return (
        <table className="register-table table-bordered">
            <thead>
            <tr>
                <th>Uhrzeit/Tag</th>
                {days.map(day => <th key={day}>{day}</th>)}
            </tr>
            </thead>
            <tbody>
            {times.map(time => (
                <tr key={time}>
                    <td><strong>{time}</strong></td>
                    {days.map(day => (
                        <td key={day} className={`register-timeslot ${selectedSlots.includes(`${day}-${time}`) ? 'selected' : ''}`}
                            onClick={() => toggleSlot(day, time)}>
                        </td>
                    ))}
                </tr>
            ))}
            </tbody>
        </table>
    );
}

export default TimeSlot;
