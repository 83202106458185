import React, { useEffect, useState } from 'react';
import '../styles/HomePage.css';

function HomePage() {
    const [username, setUsername] = useState('');
    const [role_string, setRole_string] = useState('');

    useEffect(() => {
        const user = localStorage.getItem('localUserName')
        const roles = JSON.parse(localStorage.getItem('userRoles')) || [];
        setUsername(user);
        setRole_string(roles.join(', '))
    }, []);

    return (
        <div className="home-page-container">
            <h1>Hallo {username}!</h1>
            <p>Willkommen im Firm Control Panel! <br/> Hier kannst du deine Firmlinge verwalten! <br/> Bitte beachte,
            dass sich das FCP noch in der Entwicklung befindet. Es kann daher zu Fehlern kommen. Melde diese bitte bei
            Michael oder Dominik</p>
            <p>Falls dir das Menü (Header) nicht angezeigt wird lade die Seite bitte neu!</p>
            <p>Du hast die Berechtigungsgruppen {role_string}</p>
        </div>
    );
}

export default HomePage;
