import React, { useEffect, useState } from 'react';
import api from '../api';
import '../styles/ManageActivity.css';

function ManageActivity() {
    const [firmstunden, setFirmstunden] = useState([]);
    const [firmsonntage, setFirmsonntage] = useState([]);
    const [currentFirmstunde, setCurrentFirmstunde] = useState(null);
    const [currentFirmsonntag, setCurrentFirmsonntag] = useState(null);
    const [showFirmstundeModal, setShowFirmstundeModal] = useState(false);
    const [showFirmsonntagModal, setShowFirmsonntagModal] = useState(false);

    useEffect(() => {
        loadFirmstunden();
        loadFirmsonntage();
    }, []);

    const loadFirmstunden = async () => {
        try {
            const response = await api.get('/api/firmstunde');
            setFirmstunden(response.data);
        } catch (error) {
            console.error('Error loading Firmstunden:', error);
        }
    };

    const loadFirmsonntage = async () => {
        try {
            const response = await api.get('/api/firmsonntag');
            setFirmsonntage(response.data);
        } catch (error) {
            console.error('Error loading Firmsonntage:', error);
        }
    };

    const deleteFirmstunde = async (id) => {
        if (window.confirm('Möchten Sie diese Firmstunde wirklich löschen?')) {
            try {
                await api.delete(`/api/firmstunde/${id}`);
                loadFirmstunden();
            } catch (error) {
                console.error('Error deleting Firmstunde:', error);
            }
        }
    };

    const deleteFirmsonntag = async (id) => {
        if (window.confirm('Möchten Sie diesen Firmsonntag wirklich löschen?')) {
            try {
                await api.delete(`/api/firmsonntag/${id}`);
                loadFirmsonntage();
            } catch (error) {
                console.error('Error deleting Firmsonntag:', error);
            }
        }
    };

    const handleFirmstundeEdit = (firmstunde) => {
        setCurrentFirmstunde(firmstunde);
        setShowFirmstundeModal(true);
    };

    const handleFirmsonntagEdit = (firmsonntag) => {
        setCurrentFirmsonntag(firmsonntag);
        setShowFirmsonntagModal(true);
    };

    const handleFirmstundeSave = async () => {
        const method = currentFirmstunde.id ? 'put' : 'post';
        const url = currentFirmstunde.id ? `/api/firmstunde/${currentFirmstunde.id}` : '/api/firmstunde';
        try {
            await api[method](url, currentFirmstunde);
            setShowFirmstundeModal(false);
            setCurrentFirmstunde(null);
            loadFirmstunden();
        } catch (error) {
            console.error('Error saving Firmstunde:', error);
        }
    };

    const handleFirmsonntagSave = async () => {
        const method = currentFirmsonntag.id ? 'put' : 'post';
        const url = currentFirmsonntag.id ? `/api/firmsonntag/${currentFirmsonntag.id}` : '/api/firmsonntag';
        try {
            await api[method](url, currentFirmsonntag);
            setShowFirmsonntagModal(false);
            setCurrentFirmsonntag(null);
            loadFirmsonntage();
        } catch (error) {
            console.error('Error saving Firmsonntag:', error);
        }
    };

    const handleFirmstundeChange = (e) => {
        setCurrentFirmstunde({
            ...currentFirmstunde,
            [e.target.name]: e.target.value,
        });
    };

    const handleFirmsonntagChange = (e) => {
        setCurrentFirmsonntag({
            ...currentFirmsonntag,
            [e.target.name]: e.target.value,
        });
    };

    return (
        <div className="manage-activity-container">
            <h2 className="manage-activity-header">Firmstunden und Firmsonntage Verwaltung</h2>
            <button className="manage-activity-button" onClick={() => setShowFirmstundeModal(true)}>Firmstunde hinzufügen</button>
            <button className="manage-activity-button" onClick={() => setShowFirmsonntagModal(true)}>Firmsonntag hinzufügen</button>

            <h4>Firmstunden</h4>
            <ul className="manage-activity-list-scroll">
                {firmstunden.map((firmstunde) => (
                    <li className="manage-activity-list-item" key={firmstunde.id}>
                        <strong>{firmstunde.name}</strong>
                        <p>Inhalt: {firmstunde.content}</p>
                        <p className={"kalender-week-info"}>KW: {firmstunde.week}</p>
                        <div className="manage-activity-actions">
                            <button className="manage-activity-edit-button" onClick={() => handleFirmstundeEdit(firmstunde)}>Bearbeiten</button>
                            <button className="manage-activity-delete-button" onClick={() => deleteFirmstunde(firmstunde.id)}>Löschen</button>
                        </div>
                    </li>
                ))}
            </ul>

            <h4>Firmsonntage</h4>
            <ul className="manage-activity-list-scroll">
                {firmsonntage.map((firmsonntag) => (
                    <li className="manage-activity-list-item" key={firmsonntag.id}>
                        <strong>{firmsonntag.name}</strong>
                        <p>Inhalt: {firmsonntag.content}</p>
                        <p>Datum: {firmsonntag.date}</p>
                        <div className="manage-activity-actions">
                            <button className="manage-activity-edit-button" onClick={() => handleFirmsonntagEdit(firmsonntag)}>Bearbeiten</button>
                            <button className="manage-activity-delete-button" onClick={() => deleteFirmsonntag(firmsonntag.id)}>Löschen</button>
                        </div>
                    </li>
                ))}
            </ul>

            {showFirmstundeModal && (
                <div className="manage-activity-modal">
                    <div className="manage-activity-modal-content">
                        <h5 className="manage-activity-modal-header">{currentFirmstunde?.id ? 'Firmstunde bearbeiten' : 'Firmstunde hinzufügen'}</h5>
                        <input
                            className="manage-activity-modal-input"
                            type="text"
                            name="name"
                            value={currentFirmstunde?.name || ''}
                            onChange={handleFirmstundeChange}
                            placeholder="Name"
                        />
                        <textarea
                            className="manage-activity-modal-textarea"
                            name="content"
                            value={currentFirmstunde?.content || ''}
                            onChange={handleFirmstundeChange}
                            placeholder="Inhalt"
                        />
                        <input
                            className="manage-activity-modal-input"
                            type="number"
                            name="week"
                            value={currentFirmstunde?.week || ''}
                            onChange={handleFirmstundeChange}
                            placeholder="KW"
                        />
                        <button className="manage-activity-modal-save-button" onClick={handleFirmstundeSave}>Speichern</button>
                        <button className="manage-activity-modal-cancel-button" onClick={() => setShowFirmstundeModal(false)}>Abbrechen</button>
                    </div>
                </div>
            )}

            {showFirmsonntagModal && (
                <div className="manage-activity-modal">
                    <div className="manage-activity-modal-content">
                        <h5 className="manage-activity-modal-header">{currentFirmsonntag?.id ? 'Firmsonntag bearbeiten' : 'Firmsonntag hinzufügen'}</h5>
                        <input
                            className="manage-activity-modal-input"
                            type="text"
                            name="name"
                            value={currentFirmsonntag?.name || ''}
                            onChange={handleFirmsonntagChange}
                            placeholder="Name"
                        />
                        <textarea
                            className="manage-activity-modal-textarea"
                            name="content"
                            value={currentFirmsonntag?.content || ''}
                            onChange={handleFirmsonntagChange}
                            placeholder="Inhalt"
                        />
                        <input
                            className="manage-activity-modal-input"
                            type="date"
                            name="date"
                            value={currentFirmsonntag?.date || ''}
                            onChange={handleFirmsonntagChange}
                            placeholder="Datum"
                        />
                        <button className="manage-activity-modal-save-button" onClick={handleFirmsonntagSave}>Speichern</button>
                        <button className="manage-activity-modal-cancel-button" onClick={() => setShowFirmsonntagModal(false)}>Abbrechen</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ManageActivity;
