import React, { useState } from 'react';
import TimeSlot from '../components/TimeSlot';
import ConfirmationModal from '../components/ConfirmationModal';
import ErrorModal from '../components/ErrorModal';
import '../styles/RegistrationForm.css';
import api from '../api';

function RegistrationForm() {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        gender: '',
        birthDate: '',
        nutrition: '',
        allergies: '',
        groupPreferences: '',
        availableTimeSlots: []
    });
    const [isValid, setIsValid] = useState(false);
    const [missingFields, setMissingFields] = useState([]);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);

    const validateForm = () => {
        const fields = ['firstName', 'lastName', 'gender', 'birthDate', 'nutrition'];
        const missing = fields.filter(field => !formData[field]);
        setMissingFields(missing);
        setIsValid(missing.length === 0);
        console.log(missingFields + " " + missing.length)
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
        validateForm();
    };

    const handleRadioChange = (field, value) => {
        setFormData(prevData => ({
            ...prevData,
            [field]: value
        }));
        validateForm();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await api.post('/auth/register', formData);
            alert('Registrierung erfolgreich');
            setFormData({
                firstName: '',
                lastName: '',
                gender: '',
                birthDate: '',
                nutrition: '',
                allergies: '',
                groupPreferences: '',
                availableTimeSlots: []
            });
        } catch (error) {
            alert(`Fehler bei der Registrierung: ${error.response?.data || 'Unbekannter Fehler'}`);
        }
    };



    return (
        <div className="register-wrapper">
            <div className="register-container">
                <form id="registrationForm" onSubmit={handleSubmit}>
                    <div className="form-row">
                        <div className="form-group-register col-md-6">
                            <label className="tes123t">Vorname</label>
                            <input type="text" className="form-control" name="firstName" value={formData.firstName}
                                   onChange={handleChange} required/>
                        </div>
                        <div className="form-group-register col-md-6">
                            <label>Nachname</label>
                            <input type="text" className="form-control" name="lastName" value={formData.lastName}
                                   onChange={handleChange} required/>
                        </div>
                    </div>

                    <div className="form-group-register">
                        <label>Geschlecht</label>
                        <div className="btn-group btn-group-toggle d-flex" data-toggle="buttons">
                            <label
                                className={`btn btn-secondary flex-fill ${formData.gender === 'female' ? 'active' : ''}`}>
                                <input
                                    type="radio"
                                    name="gender"
                                    value="female"
                                    checked={formData.gender === 'female'}
                                    onChange={() => handleRadioChange('gender', 'female')}
                                    onClick={() => handleRadioChange('gender', 'female')}
                                /> Weiblich
                            </label>
                            <label
                                className={`btn btn-secondary flex-fill ${formData.gender === 'male' ? 'active' : ''}`}>
                                <input
                                    type="radio"
                                    name="gender"
                                    value="male"
                                    checked={formData.gender === 'male'}
                                    onChange={() => handleRadioChange('gender', 'male')}
                                    onClick={() => handleRadioChange('gender', 'male')}
                                /> Männlich
                            </label>
                            <label
                                className={`btn btn-secondary flex-fill ${formData.gender === 'other' ? 'active' : ''}`}>
                                <input
                                    type="radio"
                                    name="gender"
                                    value="other"
                                    checked={formData.gender === 'other'}
                                    onChange={() => handleRadioChange('gender', 'other')}
                                    onClick={() => handleRadioChange('gender', 'other')}
                                /> Andreas
                            </label>
                        </div>
                    </div>

                    <div className="form-group-register">
                        <label>Geburtsdatum</label>
                        <input type="date" className="form-control" name="birthDate" value={formData.birthDate}
                               onChange={handleChange} required/>
                    </div>

                    <div className="form-group-register">
                        <label>Ernährung</label>
                        <div className="btn-group btn-group-toggle d-flex" data-toggle="buttons">
                            <label
                                className={`btn btn-secondary flex-fill ${formData.nutrition === 'vegan' ? 'active' : ''}`}>
                                <input
                                    type="radio"
                                    name="nutrition"
                                    value="vegan"
                                    checked={formData.nutrition === 'vegan'}
                                    onChange={() => handleRadioChange('nutrition', 'vegan')}
                                    onClick={() => handleRadioChange('nutrition', 'vegan')}
                                /> Vegan
                            </label>
                            <label
                                className={`btn btn-secondary flex-fill ${formData.nutrition === 'vegetarian' ? 'active' : ''}`}>
                                <input
                                    type="radio"
                                    name="nutrition"
                                    value="vegetarian"
                                    checked={formData.nutrition === 'vegetarian'}
                                    onChange={() => handleRadioChange('nutrition', 'vegetarian')}
                                    onClick={() => handleRadioChange('nutrition', 'vegetarian')}
                                /> Vegetarisch
                            </label>
                            <label
                                className={`btn btn-secondary flex-fill ${formData.nutrition === 'omnivore' ? 'active' : ''}`}>
                                <input
                                    type="radio"
                                    name="nutrition"
                                    value="omnivore"
                                    checked={formData.nutrition === 'omnivore'}
                                    onChange={() => handleRadioChange('nutrition', 'omnivore')}
                                    onClick={() => handleRadioChange('nutrition', 'omnivore')}
                                /> Allesesser
                            </label>
                        </div>
                    </div>


                    <div className="form-group-register">
                        <label>Allergien</label>
                        <input type="text" className="form-control" name="allergies" value={formData.allergies}
                               placeholder="Bitte Allergien angeben, falls vorhanden" onChange={handleChange}/>
                    </div>

                    <div className="form-group-register">
                        <label>Wünsche, mit wem man in einer Firmgruppe sein möchte</label>
                        <textarea className="form-control" name="groupPreferences" value={formData.groupPreferences}
                                  onChange={handleChange} rows="3"/>
                    </div>

                    <div className="form-group-register">
                        <label>Verfügbare Zeitslots (Montag - Freitag, 16:00 - 19:00 Uhr)</label>
                        <TimeSlot selectedSlots={formData.availableTimeSlots}
                                  onSelect={(slots) => setFormData({...formData, availableTimeSlots: slots})}/>
                    </div>

                    <button type="submit" className="btn btn-primary" disabled={!isValid}>
                        Absenden
                    </button>

                    <ConfirmationModal show={showConfirmationModal} onClose={() => setShowConfirmationModal(false)}
                                       formData={formData}/>
                    <ErrorModal show={showErrorModal} onClose={() => setShowErrorModal(false)}
                                missingFields={missingFields}/>
                </form>
            </div>
        </div>
    );
}

export default RegistrationForm;
