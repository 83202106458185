// api.js
import axios from 'axios';

const api = axios.create({
    baseURL: process.env.NODE_ENV === 'production'
        ? 'https://firmapi.acksmi.de'  // Produktions-API-URL
        : 'http://localhost:8080', // Test-API-URL
    headers: {
        'Content-Type': 'application/json',
    },
});

api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('jwtTokenFIRM') || sessionStorage.getItem('jwtTokenFIRM');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

api.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('jwtTokenFIRM');
            sessionStorage.removeItem('jwtTokenFIRM');

            // Überprüfen, ob der Benutzer sich bereits auf der Login-Seite befindet
            if (window.location.pathname !== '/login') {
                window.location.href = '/login';
            } else {
                console.log("Bereits auf der Login-Seite.");
            }
        }
        return Promise.reject(error);
    }
);


export default api;
