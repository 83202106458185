import React from 'react';
import '../styles/ManageFirmlinge.css'

const FirmlingItem = ({ firmling, onEdit, onDetails, onDelete, userRoles }) => {
    const hasEditPermission = userRoles.includes('ROLE_SR') || userRoles.includes('ROLE_ADMIN');

    return (
        <li className="manage-activity-list-item">
            <strong>{firmling.firstName} {firmling.lastName}</strong>
            <p>Firmstunden: {firmling.completedFirmstunden.length}/12
                Firmsonntage: {firmling.completedFirmsonntage.length}/4</p>
            <div className="manage-activity-actions">
                <button className="manage-activity-edit-button" onClick={onDetails}>Firmdetails</button>
                {hasEditPermission && (
                    <button className="manage-activity-edit-button" onClick={onEdit}>Bearbeiten</button>
                )}
                {hasEditPermission && (
                    <button className="manage-activity-delete-button" onClick={onDelete}>Löschen</button>
                )}
            </div>
        </li>
    );
};

export default FirmlingItem;
