import React, { useState, useEffect } from 'react';
import api from '../api';
import '../styles/ManageFirmlinge.css'

const EditFirmlingModal = ({ firmling, onClose, reloadList }) => {
    const [formData, setFormData] = useState({ ...firmling });
    const days = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag'];
    const times = ['16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00'];

    useEffect(() => {
        setFormData((prev) => ({
            ...prev,
            availableTimeSlots: firmling.availableTimeSlots || []
        }));
    }, [firmling]);

    const handleSave = async () => {
        try {
            await api.put(`/api/firmling/${formData.id}`, formData);
            reloadList();
            onClose();
        } catch (error) {
            console.error('Error saving Firmling:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const toggleTimeSlot = (slot) => {
        setFormData((prev) => {
            const isSelected = prev.availableTimeSlots.includes(slot);
            const newSlots = isSelected
                ? prev.availableTimeSlots.filter((s) => s !== slot)
                : [...prev.availableTimeSlots, slot];
            return { ...prev, availableTimeSlots: newSlots };
        });
    };

    return (
        <div className="modal visible">
            <div className="modal-content">
                <h2>Firmling bearbeiten</h2>
                <form>
                    <div className="form-group-edit">
                        <label>Vorname:</label>
                        <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} />
                    </div>

                    <div className="form-group-edit">
                        <label>Nachname:</label>
                        <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} />
                    </div>

                    <div className="form-group-edit">
                        <label>Geburtsdatum:</label>
                        <input type="date" name="birthDate" value={formData.birthDate} onChange={handleChange} />
                    </div>

                    <div className="form-group-edit">
                        <label>Geschlecht:</label>
                        <select name="gender" value={formData.gender} onChange={handleChange}>
                            <option value="male">Männlich</option>
                            <option value="female">Weiblich</option>
                            <option value="other">Divers</option>
                        </select>
                    </div>

                    <div className="form-group-edit">
                        <label>Ernährungspräferenzen:</label>
                        <select name="nutrition" value={formData.nutrition} onChange={handleChange}>
                            <option value="vegan">Keine tierischen Produkte</option>
                            <option value="meet">Keine Einschränkung</option>
                            <option value="vegetarian">Kein Fleisch</option>
                        </select>
                    </div>

                    <div className="form-group-edit">
                        <label>Allergien:</label>
                        <input type="text" name="allergies" value={formData.allergies} onChange={handleChange}/>
                    </div>

                    <div className="form-group-edit">
                        <label>Präferenzen für Firmgruppe:</label>
                        <input type="text" name="groupPreferences" value={formData.groupPreferences} onChange={handleChange} />
                    </div>

                    <div className="form-group-edit">
                        <label>Verfügbare Zeitslots (Montag - Freitag, 16:00 - 19:00 Uhr)</label>
                        <table className="time-slot-table">
                            <thead>
                            <tr>
                                <th>Uhrzeit/Tag</th>
                                {days.map((day) => (
                                    <th key={day}>{day}</th>
                                ))}
                            </tr>
                            </thead>
                            <tbody>
                            {times.map((time) => (
                                <tr key={time}>
                                    <td>{time}</td>
                                    {days.map((day) => {
                                        const slot = `${day}-${time}`;
                                        return (
                                            <td key={slot}>
                                                <input
                                                    type="checkbox"
                                                    checked={formData.availableTimeSlots.includes(slot)}
                                                    onChange={() => toggleTimeSlot(slot)}
                                                />
                                            </td>
                                        );
                                    })}
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </form>
                <button onClick={handleSave} className="manage-activity-edit-button">Speichern</button>
                <button onClick={onClose} className="manage-activity-modal-cancel-button">Abbrechen</button>
            </div>
        </div>
    );
};

export default EditFirmlingModal;
