import React, { useEffect, useMemo } from 'react';
import '../styles/Board.css';
import Sortable from 'sortablejs';
import api from '../api';

function Board() {
    const dayNames = useMemo(() => ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag'], []);

    useEffect(() => {
        const loadFirmlinge = async () => {
            try {
                const response = await api.get('/api/firmlinge');
                const firmlinge = response.data;

                document.querySelectorAll('.firm-list').forEach((list) => {
                    list.querySelectorAll('.card').forEach((card) => card.remove());
                });

                firmlinge.forEach((firmling) => {
                    const card = `
                    <div class="card" data-id="${firmling.id}" data-group="${firmling.firmgruppe || 'UNGEORDNET'}">
                        <strong class="titlename">${firmling.firstName} ${firmling.lastName}</strong>
                        <p class="wuensche">Wünsche: ${firmling.groupPreferences}</p>
                        ${createTimeslotTable(firmling.availableTimeSlots)}
                    </div>`;
                    const group = firmling.firmgruppe ? firmling.firmgruppe.toLowerCase() : 'unordered';
                    document.querySelector(`#${group}`).insertAdjacentHTML('beforeend', card);
                });

                makeListsSortable();
            } catch (error) {
                console.error('Error loading Firmlinge:', error);
            }
        };

        const createTimeslotTable = (availableTimeSlots) => {
            let tableHTML = '<table class="timeslot-table"><thead><tr><th></th>';
            dayNames.forEach(day => {
                tableHTML += `<th>${day}</th>`;
            });
            tableHTML += '</tr></thead><tbody>';

            const times = ['16:00', '16:30', '17:00', '17:30', '18:00', '18:30'];
            times.forEach(time => {
                tableHTML += `<tr><td>${time}</td>`;
                dayNames.forEach(day => {
                    const timeslot = `${day}-${time}`;
                    const selectedClass = availableTimeSlots.includes(timeslot) ? 'selected' : '';
                    tableHTML += `<td class="timeslot ${selectedClass}">${selectedClass ? '✓' : ''}</td>`;
                });
                tableHTML += '</tr>';
            });
            tableHTML += '</tbody></table>';
            return tableHTML;
        };

        const makeListsSortable = () => {
            document.querySelectorAll('.firm-list').forEach((list) => {
                new Sortable(list, {
                    group: 'firmlinge',
                    animation: 150,
                    draggable: '.card',
                    onEnd: (evt) => {
                        const id = evt.item.dataset.id;
                        const group = evt.to.id;
                        updateFirmlingGroup(id, group);
                    }
                });
            });
        };

        const updateFirmlingGroup = async (id, group) => {
            try {
                await api.put(`/api/firmlinge/updateFirmgruppe/${id}/${group.toUpperCase()}`);
                console.log(`Firmling ${id} updated to group ${group}`);
            } catch (error) {
                console.error('Error updating Firmling group:', error);
            }
        };
        loadFirmlinge();
    }, [dayNames]);

    return (
        <div className="acksmiContent">
            <div className="acksmiboard" id="board">
                {dayNames.map((day) => (
                    <div className="firm-list" key={day.toLowerCase()} id={day.toLowerCase()}>
                        <h3>{day}</h3>
                    </div>
                ))}
                <div className="firm-list" id="unordered">
                    <h3>Ungeordnet</h3>
                </div>
            </div>
        </div>
    );
}

export default Board;
